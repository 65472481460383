<template>
    <div id="app">
        <div class="bg_green" style="padding-bottom:3vw">
            <div class="white_box3" style="box-shadow:none">
                <div class="white_box3_in" style="padding-bottom:0;">

                    <!-- 用户 -->
                    <!--<div class="user under_line pd_3p6_0 clear">-->
                        <!--<div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>-->
                        <!--<div class="user_msg">-->
                            <!--<span>何铭华</span>-->
                            <!--<label>2021-01-01加入</label>-->
                        <!--</div>-->
                        <!--<div class="user_r2">-->
                            <!--<button class="button_dark">取消共享</button>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!--<div class="user under_line pd_3p6_0 clear">-->
                        <!--<div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>-->
                        <!--<div class="user_msg">-->
                            <!--<span>何铭华</span>-->
                            <!--<label>2021-01-01加入</label>-->
                        <!--</div>-->
                        <!--<div class="user_r2">-->
                            <!--<button class="button_dark">取消共享</button>-->
                        <!--</div>-->
                    <!--</div>-->
                    <!--<div class="user under_line pd_3p6_0 clear">-->
                        <!--<div class="user_avatar" :style="{backgroundImage:`url(${img})`}"></div>-->
                        <!--<div class="user_msg">-->
                            <!--<span>何铭华</span>-->
                            <!--<label>2021-01-01加入</label>-->
                        <!--</div>-->
                        <!--<div class="user_r2">-->
                            <!--<button class="button_dark">取消共享</button>-->
                        <!--</div>-->
                    <!--</div>-->

                    <button class="button_green">共享到微信好友</button>

                </div>

            </div>
        </div>


    </div>
</template>


<style scoped>
</style>

<script>
    import Vue from 'vue'
    import {ACCESS_TOKEN, ACCESS_CustomerUser} from '@/store/mutation-types'
    import {post} from '@/utils/http'
    import moment from 'moment'
    import wx from 'weixin-js-sdk'
    import {appId} from '@/config/env.dev'

    export default {
        data() {
            return {
                img: require('../assets/images/avatar.png'),
                img2: require('../assets/images/avatar2.png'),
            };
        },
        mounted() {
            this.customerUser = Vue.ls.get(ACCESS_CustomerUser)
            // /login?shareId=0000000000
            // state=shareId:0000000000
            // 获取到accessToken后，自动注册，跳转到首页

            let domain = document.domain
            var link = 'http://' + domain + '/share'
            // let link = 'http://testxuexiao.fsytss.com/share'
            this.setShareHeader(
                wx,
                {
                    domainUrl: domain,
                    url: link,
                    link: link,
                    // imgUrl: imgUrl,
                    title: '共享',
                    desc: '共享到微信好友',
                    type: 'link'
                },
                {
                    updateTimelineShareData: function () {
                        console.log('updateTimelineShareData')
                    },
                    onMenuShareTimeline: function () {
                        console.log('onMenuShareTimeline')
                    },
                    updateAppMessageShareData: function () {
                        console.log('updateAppMessageShareData')
                    },
                    onMenuShareAppMessage: function () {
                        console.log('onMenuShareAppMessage')
                    },
                    error: function (res) {
                        console.log('error')
                    }
                }
            )

            this.loading = true
            post('api/share/v2/search', {}).then(res => {
                this.loading = false
            }).catch(() => {
                this.loading = false
            });
        },
        methods: {
            setShareHeader(wx, data, func) {
                if (!data.url) {
                    data.url = location.href.split('#')[0]
                }

                post('api/wechat/v2/getSignature', {
                    url: data.url
                }).then(response => {
                    wx.config({
                        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                        appId: appId, // 必填，公众号的唯一标识
                        timestamp: response.data.timestamp, // 必填，生成签名的时间戳
                        nonceStr: response.data.nonceStr, // 必填，生成签名的随机串
                        signature: response.data.signature, // 必填，签名
                        jsApiList: [
                            // 'updateTimelineShareData',
                            // 'updateAppMessageShareData',
                            'onMenuShareTimeline',
                            'onMenuShareAppMessage'
                        ] // 必填，需要使用的JS接口列表
                    })

                    var domainUrl = data.domainUrl

                    // 需在用户可能点击分享按钮前就先调用
                    if (!data.link) {
                        data.link = document.location.href
                    }

                    if (!data.imgUrl) {
                        data.imgUrl = domainUrl + '/static/img/getCode/logo1.png'
                    }

                    if (!data.title) {
                        data.title = document.title
                    }

                    if (!data.desc) {
                        data.desc = document.title
                    }

                    var type = 'link' // 分享类型,music、video或link，不填默认为link

                    wx.updateTimelineShareData({
                        title: data.title,
                        desc: data.desc,
                        link: data.link,
                        url: data.link,
                        // imgUrl: data.imgUrl,
                        type: type,
                        success: func.updateTimelineShareData
                    })

                    wx.onMenuShareTimeline({
                        title: data.title,
                        desc: data.desc,
                        link: data.link,
                        url: data.link,
                        // imgUrl: data.imgUrl,
                        type: type,
                        success: func.onMenuShareTimeline
                    })

                    wx.updateAppMessageShareData({
                        title: data.title,
                        desc: data.desc,
                        link: data.link,
                        url: data.link,
                        // imgUrl: data.imgUrl,
                        type: type,
                        success: func.updateAppMessageShareData
                    })

                    wx.onMenuShareAppMessage({
                        title: data.title,
                        desc: data.desc,
                        link: data.link,
                        url: data.link,
                        // imgUrl: data.imgUrl,
                        type: type,
                        dataUrl: '',
                        success: func.onMenuShareAppMessage
                    })
                })

                wx.error(func.error)
            }
        }
    }
    ;
</script>
